/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import FileUploader from "../FileUploader";
import Papa from "papaparse";
import { useFormik, FormikErrors } from "formik";
import { v4 } from "uuid";
import { saveContactsFromFile } from "../../core/api";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../Loader";

interface FormValues {
  name: string;
  file: File | undefined;
}

interface Props {
  isModalOpened: boolean;
  setIsModalOpened: (val: boolean) => void;
}

const AddContactsFromFileModal: React.FC<Props> = ({ isModalOpened, setIsModalOpened }: Props) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState<any[]>([]);
  const [selected, setSelected] = useState<string | undefined>();
  const [error, setError] = useState<string>("");
  const [parseFileError, setParseFileError] = useState<string>("");
  const [parsedCsvFile, setParsedCsvFile] = useState<any[]>([]);
  //   const { isModalOpened, openModal, closeModal } = useModal();

  const { getAccessTokenSilently } = useAuth0();

  const { handleSubmit, handleChange, handleBlur, errors, touched, values, setFieldValue, setFieldTouched, setFieldError, resetForm } =
    useFormik<FormValues>({
      initialValues: {
        name: "",
        file: undefined
      },
      validate: (values: FormValues) => {
        const errors: FormikErrors<FormValues> = {};

        if (!values.name) {
          errors.name = "Required";
        }

        if (!values.file) {
          errors.file = "Required";
        }

        return errors;
      },
      onSubmit: (values) => {
        setParseFileError("");
        setIsLoading(true);
        Papa.parse(values.file as any, {
          header: true,
          error: (err) => {
            console.error(err);
            setIsLoading(false);
            setParseFileError("Something went wrong, please try again");
          },
          complete: function (results, file: File) {
            if (file.name.split(".").slice(-1)[0] !== "csv" && file.type !== "text/csv") {
              setIsLoading(false);
              return setParseFileError("Only csv files are supported");
            }
            if (results.data instanceof Array) {
              setItems(
                results.data.map((item: any) => ({
                  ...item,
                  id: v4()
                }))
              );
              setParsedCsvFile(results.data);
              //   openModal();
            } else {
              setParseFileError("Something went wrong, please try again");
            }
            setIsLoading(false);
          }
        });

        Papa.parse(values.file as any, {
          header: false,
          error: (err) => {
            console.error(err);
            setIsLoading(false);
            setParseFileError("Something went wrong, please try again");
          },
          complete: function (results) {
            if (results.data instanceof Array) {
              setParsedCsvFile(results.data);
            } else {
              setParseFileError("Something went wrong, please try again");
            }
            setIsLoading(false);
          }
        });
      }
    });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const file = event.dataTransfer.files?.[0];
    setSelectedFile(file);
  };

  const getError = (v: keyof FormValues) => {
    return touched[v] && errors[v] ? errors[v] : error;
  };

  const onSelectFile = async (f: File) => {
    console.log(JSON.stringify(f));
    setIsLoading(true);
    saveContactsFromFile(f, await getAccessTokenSilently())
      .then(() => {
        setIsLoading(false);
        setIsModalOpened(false);
      })
      .catch((e) => {
        console.error(e);
        setError(e.message);
        setIsLoading(false);
      });
    // setFieldValue("file", f, true);
    // setTimeout(() => {
    //   setFieldTouched("file", true);
    // }, 0);
  };

  const deleteFile = () => {
    setFieldValue("file", undefined, false);
    setTimeout(() => {
      setFieldTouched("file", false);
      setFieldError("file", undefined);
    }, 0);
  };

  const handleClickDone = () => {
    if (!selected) {
      return setError("Select at least one column");
    }
    // openConfirmModal();
    // uploadContactsByFile(values.name, parsedCsvFile, selected!, false);
    resetForm();
  };

  useEffect(() => {
    if (selected || isModalOpened) setError("");
  }, [selected, isModalOpened]);

  return (
    <div className="">
      {isLoading && <Loader />}
      <FileUploader onSelect={onSelectFile} error={getError("file")} name="upload-csv" />
      {/* <input type="file" onChange={handleFileChange} />
      <div style={{ border: "1px dashed gray", padding: "1rem" }} onDragOver={handleDragOver} onDrop={handleDrop}>
        {selectedFile ? <p>Selected file: {selectedFile.name}</p> : <p>Drag and drop a file here, or click to browse files</p>}
      </div> */}
    </div>
  );
};

export default AddContactsFromFileModal;
