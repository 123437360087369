import { Dispatch } from "react";
import { useFormik } from "formik";
import { UserEmailSettings } from "./EmailBuilderComponent";
import Card from "../Card";
import "./GenerateEmailForm.scss";
import { Button } from "../Button/Button";
import { Article } from "../../core/types";
import { DropdownItem } from "../Dropdown/Dropdown";
import Input from "../Input/Input";
import { generateEmail } from "../../core/api";
import { useAuth0 } from "@auth0/auth0-react";

interface Props {
  className?: string;
  articles: Article[];
  contactUuid?: string;
  setEmailOutput: Dispatch<React.SetStateAction<string>>;
  setUserSetup: Dispatch<React.SetStateAction<UserEmailSettings>>;
  setIsLoading: Dispatch<React.SetStateAction<boolean>>;
  userSetup?: UserEmailSettings;
}

export interface ArticleDropdownItem extends DropdownItem {
  date: string;
  sourceName: string;
  sourceUrl: string;
}

export function GenerateEmailForm({ articles, contactUuid, className, setUserSetup, setEmailOutput, setIsLoading, userSetup }: Props) {
  const { getAccessTokenSilently } = useAuth0();
  const formikProps = useFormik({
    initialValues: {
      language: userSetup?.language || "Svenska",
      user_company_name: userSetup?.user_company_name,
      description: userSetup?.description,
      source_url: undefined
    },
    onSubmit: async (values) => {
      if (!contactUuid) {
        return;
      }
      setIsLoading(true);
      setUserSetup(values);
      await generateEmail(contactUuid, values, await getAccessTokenSilently())
        .then((res) => setEmailOutput(res.completion))
        .finally(() => setIsLoading(false));
    }
  });

  return (
    <Card className={`${className} EmailForm--base`}>
      <ol className=" align-middle h-full">
        <form className="flex-col h-full relative" onSubmit={formikProps.handleSubmit}>
          <div className="EmailForm--option mt-5">
            <label className="EmailForm--label">Language</label>
            <select
              id="language"
              className="EmailForm--select "
              onChange={formikProps.handleChange}
              autoComplete="off"
              name="language"
              defaultValue={formikProps.values.language}
              placeholder="">
              <option>Svenska</option>
              <option>English</option>
            </select>
          </div>
          <div className="EmailForm--option ">
            <label className="EmailForm--label">Company/Product Name*</label>
            <Input
              id="company-product-name"
              className="text-[12px] mt-[3px]"
              onChange={formikProps.handleChange}
              autoComplete="off"
              name="user_company_name"
              defaultValue={formikProps.values.user_company_name}
              placeholder="Sharpreach"
              error={undefined}></Input>
          </div>
          <div className="EmailForm--option">
            <label className="EmailForm--label">Value Proposition*</label>
            <textarea
              id="product-description"
              className="EmailForm--input"
              rows={6}
              onChange={formikProps.handleChange}
              autoComplete="off"
              name="description"
              defaultValue={formikProps.values.description}
              placeholder={
                formikProps.values.language === "Svenska"
                  ? "Vi hjälper vi dig att upptäcka unika försäljningsmöjligheter och knyter dig till intresserade kunder som ökar tillväxten i ditt företag."
                  : "We help you discover unique sales opportunities, connect with interested clients, and close deals quicker."
              }></textarea>
          </div>
          <div className="EmailForm--option">
            <label className="EmailForm--label">{"Scoops you would like to refer to"}</label>
            <Input
              id="article-source-url"
              className="text-[12px] mt-[3px] "
              autoComplete="off"
              onChange={formikProps.handleChange}
              name="source_url"
              placeholder={formikProps.values.language === "Svenska" ? "Ange länk eller välj nedan." : "Enter url or choose article below."}
              value={formikProps.values.source_url}
              error={undefined}></Input>
            {articles.length > 0 && (
              <div className="EmailForm--list shadow-sm  overflow-y-scroll ">
                {articles.map((a, i) => {
                  return (
                    <div
                      key={a.title}
                      className={`EmailForm--item ${i === 0 ? "rounded-t-[10px]" : ""} ${i === articles.length - 1 ? "rounded-b-[10px] pb-3" : ""} ${
                        a.source_url === formikProps.values.source_url ? "bg-[#877cff]" : ""
                      }`}
                      onClick={() => {
                        formikProps.setFieldValue("source_url", a.source_url);
                      }}>
                      <div className="px-2">
                        <label className={` ${a.source_url === formikProps.values.source_url ? "text-white  " : ""}`}>{a.title}</label>
                      </div>
                      <div className="">
                        <label className="text-purple ml-2 text-xs">{a.source_name}</label>
                        <label className="text-gray text-xs">{` | ${a.date}`}</label>
                      </div>
                      {i !== articles.length - 1 ? <hr className={` EmailForm--divider `}></hr> : ""}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <div className="EmailForm--option absolute bottom-6 w-[100%] ">
            <Button className="EmailForm--button" disabled={contactUuid ? false : true}>
              Generate Content
            </Button>
          </div>
        </form>
      </ol>
    </Card>
  );
}
