import { FormikErrors, useFormik } from "formik";
import { useEffect } from "react";
import { Button } from "./Button/Button";
import Input from "./Input/Input";
import Modal from "./Modal";

interface Props {
  isModalOpened: boolean;
  closeModal: () => void;
  onSubmit: (name: string, resetForm: () => void) => void;
  initialName?: string;
  btnLabel: string;
  validate?: (v: FormValues) => FormikErrors<FormValues>;
  placeholder?: string;
}

interface FormValues {
  name: string;
}

export default function CreateEditNameModal({
  isModalOpened,
  closeModal,
  onSubmit,
  initialName = "",
  validate,
  btnLabel,
  placeholder,
  ...rest
}: Props) {
  const { handleSubmit, handleChange, handleBlur, errors, touched, values, resetForm } = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      name: initialName
    },
    validate,
    onSubmit: ({ name }) => {
      onSubmit(name, resetForm);
    }
  });

  useEffect(() => {
    if (isModalOpened) {
      resetForm();
    }
  }, [isModalOpened]);

  return (
    <Modal isOpened={isModalOpened} closeModal={closeModal}>
      <form onSubmit={handleSubmit} className="max-w-[260px] w-full mx-auto" {...rest}>
        <Input
          data-testid="name-input"
          name="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.name && errors.name ? errors.name : undefined}
          className="pb-2"
          placeholder={placeholder}
        />
        <Button styling="outline-purple" className="w-full" size="md" data-testid="submit-name-btn">
          {btnLabel}
        </Button>
      </form>
    </Modal>
  );
}
