import { FormikErrors, useFormik } from "formik";
import { MouseEvent, useEffect, useState } from "react";
import { Button } from "../components/Button/Button";
import Card from "../components/Card";
import Input from "../components/Input/Input";
import { isEmail } from "../core/helpers";
import PageWrapper from "../wrappers/PageWrapper";
import { ReactComponent as PencilIcon } from "../icons/pencil.svg";
import Tooltip from "../components/Tooltip";
import "./Settings.scss";
import { getCurrentPlan, getOrganizationSettings, resetPassword } from "../core/api";
import Loader from "../components/Loader";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { User } from "../core/types";
import EmailInvite from "../components/Settings/EmailInvite";
import ScoopPreferences from "../components/Settings/ScoopPreferences";

interface FormValues {
  email: string;
}
interface Editable {
  email: boolean;
}

const initialEditableState = {
  first_name: false,
  last_name: false,
  email: false
};

export default function Settings() {
  const [editable, setEditable] = useState<Editable>({
    ...initialEditableState
  });
  const [isLoading, setIsLoading] = useState(false);
  const [currentPlan, setCurrentPlan] = useState("");
  const [currentUserData, setCurrentUserData] = useState<User>();
  const [articleTag, setArticleTag] = useState<string>();
  const { getAccessTokenSilently } = useAuth0();

  const { handleChange, handleBlur, errors, touched, values, setValues } = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      email: ""
    },
    validate: (values: FormValues) => {
      const errors: FormikErrors<FormValues> = {};
      if (!values.email) {
        errors.email = "Required";
      }
      if (values.email && !isEmail(values.email)) {
        errors.email = "Email is invalid";
      }

      return errors;
    },
    onSubmit: () => {
      setIsLoading(false);
    }
  });

  const currentUser = useQuery({
    queryKey: ["UserWithOrgSettings"],
    queryFn: async () =>
      await getOrganizationSettings(await getAccessTokenSilently()).then((res) => {
        setValues(res as FormValues);
        setCurrentUserData(res);
        //super wonky logic due to difficulties getting the article tag due to nested objects
        setArticleTag(res.organization?.article_industry_tags?.length ? res.organization.article_industry_tags[0].name : "");
        return res;
      })
    // refetchOnWindowFocus: true
  });

  useEffect(() => {
    setIsLoading(true);
    getUserSettings();

    setIsLoading(false);
  }, [currentUserData]);

  async function getUserSettings() {
    await getCurrentPlan(await getAccessTokenSilently()).then((res) => {
      if (res.name) {
        setCurrentPlan(res.name);
      } else if (res.lifetime_access) {
        setCurrentPlan("Lifetime Access");
      } else {
        setCurrentPlan("Standard");
      }
    });
  }

  async function onOnboardingSubmit(e: MouseEvent<Element, globalThis.MouseEvent>) {
    e?.preventDefault();
    setIsLoading(true);
    await currentUser.refetch();
    setCurrentUserData(currentUser.data);
    setIsLoading(false);
  }

  const getError = (v: keyof FormValues) => {
    return touched[v] && errors[v] ? errors[v] : undefined;
  };

  const renderField = (label: string, field: keyof FormValues) => {
    return (
      <div>
        <div className="flex items-center pb-6">
          <div className="w-[100px] text-[11px] leading-[13px] text-gray">{label}</div>
          <div>
            {editable[field] ? (
              <Input
                errorClassName="absolute"
                onChange={handleChange}
                onBlur={handleBlur}
                error={getError(field)}
                name={field}
                value={values[field]}
                data-testid={field}
              />
            ) : (
              <div className="text-[11px] leading-[13px] relative">
                {values[field]}
                <button
                  className="absolute top-[-8px] right-[-10px] IconBtn"
                  type="button"
                  onClick={() => {
                    setEditable((prev) => ({
                      ...prev,
                      [field]: true
                    }));
                  }}
                  data-testid={`${field}-change-btn`}>
                  <PencilIcon />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col grow">
      <PageWrapper data-testid="Settings">
        {isLoading && <Loader />}
        <Card className="mb-5">
          <div className="text-xl leading-[24px] font-semibold pt-3 pb-3.5 pl-12 pr-4 border-b border-gray-100 mb-5">My Details</div>
          <div className="pl-12 pr-4">
            {renderField("Email", "email")}
            <div className="flex items-center pb-6">
              <div className="w-[100px] text-[11px] leading-[13px] text-gray">Current plan</div>
              <div className="text-[11px] leading-[13px]">{currentPlan}</div>
            </div>
          </div>
        </Card>
        <Card className="mb-5">
          <>
            <div className="text-xl font-semibold leading-[24px] pt-3 pb-3.5 pl-12 pr-4 border-b border-gray-100 mb-5">Password</div>
            <div className="pl-12 pr-4 pb-4">
              <Tooltip
                content={`Reset password email sent to ${values.email}`}
                onClick={async (e, isOpened, setIsOpened) => {
                  e.stopPropagation();
                  if (isOpened) return;
                  resetPassword(values.email, await getAccessTokenSilently());
                  setIsOpened(true);
                }}>
                <Button styling="outline" className="w-[185px] block text-center Button--Settings" type="button">
                  Reset Password
                </Button>
              </Tooltip>
            </div>
          </>
        </Card>
        <EmailInvite className="w-full h-40 mb-5" headline="Invite Users"></EmailInvite>
        {currentUser.data?.organization && articleTag && (
          <Card>
            <div className="text-xl leading-[24px] font-semibold pt-3 pb-3.5 pl-12 pr-4 border-b border-gray-100 mb-5">My Details</div>
            <div className="pl-12 pr-4">
              <ScoopPreferences organization={currentUser.data.organization} onSubmit={onOnboardingSubmit} articleTag={articleTag}></ScoopPreferences>
            </div>
          </Card>
        )}
      </PageWrapper>
    </div>
  );
}
