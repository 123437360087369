import axios from "./axios";
import { Batch } from "../pages/BatchGenerate/BatchGenerate";
import { PostNewBatchInput } from "../pages/BatchGenerate/NewBatch";
import { Article, Company, CompanyOfInterest, Contact, Group, IceBreaker, JobListing, Mention, Prospect, Status, User } from "./types";
import { v4 as uuidv4 } from "uuid";
import { StripePlan } from "../pages/Subscription";
import { sleep } from "./helpers";
import { Comment } from "../core/types";

//TODO: refactor into smaller classes

export enum EndpointsEnum {
  CommentCompanyPost = "/comments/company/post",
  CommentEdit = "/comments/:uuid",
  CommentCompanyGet = "/comments/company/:uuid",
  CommentContactGet = "/comments/contact/:uuid",
  CommentContactPost = "/comments/contact/post",
  CommentDelete = "/comments/:uuid",
  DeleteContact = "/contacts/:uuid",
  GetContacts = "/contacts/organization/all",
  GetContact = "/contacts/:uuid",
  GetSavedContactByLinkedin = "/contacts/linkedin/:linkedin_url",
  SaveContact = "/contacts/prospect/add",
  SaveContactsFromFile = "contacts/csv/add",
  GetEmail = "/contacts/email/get/:linkedin_url",
  GenerateEmail = "contacts/email/generate/:uuid",
  SaveEmailDraft = "contacts/email/save/:uuid",
  SetNewContactOwner = "contacts/owner/set",
  SaveContactFromMention = "contacts/save/frommention",
  GetEmailAndEnrichExistingContact = "contacts/enrich/saved/email",
  GetEmailAndEnrichUnsavedContact = "contacts/enrich/unsaved/email",
  GetSavedContactsForCompany = "/contacts/company/get/:company_domain",
  ScheduleReminder = "contacts/reminder/schedule",
  CancelReminder = "contacts/reminder/cancel",
  AddContactWithInfo = "contacts/info/add",
  InitiateEmailLookup = "/email/get/:linkedin_url",
  AddContactToGroup = "/groups/:uuid/contacts/",
  CreateGroup = "/groups/create",
  GetAllGroups = "/groups/all",
  GetGroup = "/groups/:uuid/",
  DeleteGroup = "/groups/:uuid",
  AddExistingContactToGroup = "/groups/add/:uuid",
  RemoveContactFromGroup = "/groups/remove/:uuid/",
  GetBatchGenerate = "/icebreakers/csvfiles/fetch/",
  DeleteBatch = "/icebreakers/csvfiles/:uuid",
  PostBatchGenerate = "/icebreakers/generate/bulk/",
  PostGenerateProfile = "/icebreakers/contact/:linkedin_url/",
  SaveIcebreaker = "/icebreakers/:contact_uuid/",
  GenerateIcebreakers = "/icebreakers/:linkedinUrl/generate/",
  GetStripePlans = "/membership/plans/",
  GetCurrentStripePlan = "/membership/",
  GetIndividualStripePlan = "/membership/plans/:plan_id/",
  GetStripeCheckout = "/membership/checkout/",
  GetStripeBillingPortal = "/membership/billing-portal/",
  UpgradeSubscription = "/membership/update/",
  NewsFlow = "/newsflow/all",
  NewsFlowOptions = "/newsflow/profile/options/list",
  IsSubscribedToArticle = "/newsflow/article/issubscribed/:uuid",
  SetNewArticleOwner = "/newsflow/article/assign",
  IsCompanySavedByOrg = "/organization/company/issaved/:uuid",
  GetSavedCompaniesForOrg = "/organization/company/get",
  SaveCompanyForOrg = "/organization/company/update",
  GetOrganizationMembers = "/organization/users/get",
  GetOnboardingOptions = "/organization/onboarding/options",
  UpdateScoopPreferences = "/organization/preferences/update",
  GetOrganizationSettings = "/organization/settings",
  GetArticlesForCompany = "/prospects/company/articles/:domain",
  CompanyInfo = "/prospects/company/domain/:domain",
  SetNewCompanyOwner = "/prospects/company/assign",
  ProspectSearch = "/prospects/people/search",
  ProspectOptions = "/prospects/people/roles",
  GetCompanyOfInterest = "/prospects/company/suggestion/get/:page",
  IsSubscribedToCompany = "/prospects/company/issubscribed/:uuid",
  GetJobListings = "/prospects/joblisting/get/:page",
  SetNewJoblistingOwner = "/prospects/joblisting/assign",
  GetCompanyRelatedJobListings = "/prospects/joblisting/company/:uuid",
  SetNewCompanySuggestionOwner = "/prospects/company/suggestion/assign",
  GetSettings = "/settings/",
  GetArticleEventTags = "/tags/articles/available",
  SetContactStatus = "/tags/contacts/status/set",
  SetCompanyTag = "tags/company/set",
  UnsetCompanyTag = "tags/company/unset",
  GetCompanyTags = "tags/company/available",
  DeleteCompanyTag = "tags/company",
  GetStatusTags = "/tags/status/available",
  GetCurrentUser = "/users/me/",
  ResetPassword = "/users/reset-password/:email/",
  UpdatePreferences = "/users/preferences/update",
  SaveCompany = "/users/companies/update",
  SaveArticle = "/users/articles/update",
  InviteUsers = "/users/organization/invite",
  GetCurrentUserForNewsflow = "/users/current/newsflow",
  GetWebsiteVisitScript = "organization/website-visits/script",
  GetWebsiteVisits = "/website-visit/all",
  GetWebsiteVisitsPaginated = "/website-visit/paginated",
  GetJobListingsPaginated = "/joblisting/get"
}

export interface SetNewOwnerApiCall {
  (targetUuid: string, ownerUuid: string, accesstoken: string): Promise<void>;
}

export async function getBatchGenerate(accesstoken: string): Promise<Batch[]> {
  const res = await axios.get<Batch[]>(`${EndpointsEnum.GetBatchGenerate}`, {
    headers: {
      Authorization: `Bearer ${accesstoken}`
    }
  });
  return res.data;
}

export const postNewBatch = async ({ name, data, column }: PostNewBatchInput, accesstoken: string) => {
  await axios.post<void>(
    `${EndpointsEnum.PostBatchGenerate}`,
    {
      fileName: name,
      csvFile: data,
      linkedinColumnName: column
    },
    {
      headers: {
        Authorization: `Bearer ${accesstoken}`
      }
    }
  );
  return {
    uuid: uuidv4(),
    file_name: name,
    status: "in_progress",
    created_at: Date.now(),
    size: data.length - 1
  };
};

export const postGenerateProfile = async (linkedinUrl: string, accesstoken: string) => {
  linkedinUrl = linkedinUrl.endsWith("/") ? linkedinUrl : linkedinUrl + "/";
  const res = await axios.get<Contact>(`${EndpointsEnum.PostGenerateProfile.replace(":linkedin_url", encodeURIComponent(linkedinUrl))}`, {
    headers: {
      Authorization: `Bearer ${accesstoken}`
    }
  });
  return res.data;
};

export const getContacts = async (accesstoken: string) => {
  const res = await axios.get<Contact[]>(`${EndpointsEnum.GetContacts}`, {
    headers: {
      Authorization: `Bearer ${accesstoken}`
    }
  });
  return res.data;
};

export const getContact = async (uuid: string, accesstoken: string) => {
  const res = await axios.get<Contact>(`${EndpointsEnum.GetContact.replace(":uuid", uuid)}`, {
    headers: {
      Authorization: `Bearer ${accesstoken}`
    }
  });
  return res.data;
};

export const getSavedContactByLinkedin = async (linkedin_url: string, accesstoken: string) => {
  const res = await axios.get<Contact>(`${EndpointsEnum.GetSavedContactByLinkedin.replace(":linkedin_url", encodeURIComponent(linkedin_url))}`, {
    headers: {
      Authorization: `Bearer ${accesstoken}`
    }
  });
  return res.data;
};

export const saveContact = async (contact: Prospect | Contact, accesstoken: string) => {
  const res = await axios.post<Contact>(`${EndpointsEnum.SaveContact}`, contact, {
    headers: {
      Authorization: `Bearer ${accesstoken}`
    }
  });
  return res.data;
};

export const deleteBatch = async (uuid: string, accesstoken: string) => {
  await axios.delete<void>(`${EndpointsEnum.DeleteBatch.replace(":uuid", uuid)}`, {
    headers: {
      Authorization: `Bearer ${accesstoken}`
    }
  });
};

export const deleteContact = async (uuid: string, accesstoken: string) => {
  await axios.delete<void>(`${EndpointsEnum.DeleteContact.replace(":uuid", uuid)}`, {
    headers: {
      Authorization: `Bearer ${accesstoken}`
    }
  });
};

export const fetchCommentsForContact = async (contactUuid: string, accesstoken: string) => {
  return await axios
    .get(`${EndpointsEnum.CommentContactGet.replace(":uuid", contactUuid)}`, { headers: { Authorization: `Bearer ${accesstoken}` } })
    .then((res) => res.data);
};

export const setContactOwner: SetNewOwnerApiCall = async (contactUuid: string, ownerUuid: string, accesstoken: string) => {
  return await axios
    .post(
      `${EndpointsEnum.SetNewContactOwner}`,
      { owner_uuid: ownerUuid, target_uuid: contactUuid },
      { headers: { Authorization: `Bearer ${accesstoken}` } }
    )
    .then((res) => res.data);
};

export const getEmail = async (linkedinUrl: string, accesstoken: string) => {
  const res = await axios.get(`${EndpointsEnum.GetEmail.replace(":linkedin_url", encodeURIComponent(linkedinUrl))}`, {
    headers: {
      Authorization: `Bearer ${accesstoken}`
    }
  });
  return res.data;
};

export const getEmailLookup = async (linkedinUrl: string, accesstoken: string) => {
  const res = await axios.get(`${EndpointsEnum.InitiateEmailLookup.replace(":linkedin_url", encodeURIComponent(linkedinUrl))}`, {
    headers: {
      Authorization: `Bearer ${accesstoken}`
    }
  });
  return res.data;
};

export const postCreateGroup = async (name: string, accesstoken: string) => {
  return await axios.post<Group>(
    `${EndpointsEnum.CreateGroup}`,
    { name: name },
    {
      headers: {
        Authorization: `Bearer ${accesstoken}`
      }
    }
  );
};

export const addNewContactToGroup = async (prospectInfo: Prospect, groupUuid: string, accesstoken: string) => {
  const res = await axios.post<void>(
    `${EndpointsEnum.AddContactToGroup.replace(":uuid", groupUuid)}`,
    {
      prospectInfo
    },
    {
      headers: {
        Authorization: `Bearer ${accesstoken}`
      }
    }
  );
  return res.data;
};

export const addExistingContactToGroup = async (contactUuid: string, groupUuid: string, accesstoken: string) => {
  const res = await axios.post<void>(
    `${EndpointsEnum.AddExistingContactToGroup.replace(":uuid", groupUuid)}`,
    {
      uuid: contactUuid
    },
    {
      headers: {
        Authorization: `Bearer ${accesstoken}`
      }
    }
  );
  return res.data;
};

export const removeContactFromGroup = async (contactUuid: string, groupUuid: string, accesstoken: string) => {
  const res = await axios.post<void>(
    `${EndpointsEnum.RemoveContactFromGroup.replace(":uuid", groupUuid)}`,
    {
      uuid: contactUuid
    },
    {
      headers: {
        Authorization: `Bearer ${accesstoken}`
      }
    }
  );
  return res.data;
};

export const patchGroup = async () => {
  await sleep(); //TODO: implement
  return {
    status: 200
  };
};

export const getGroups = async (accesstoken: string) => {
  const res = await axios.get<Group[]>(`${EndpointsEnum.GetAllGroups}`, {
    headers: {
      Authorization: `Bearer ${accesstoken}`
    }
  });
  return res.data;
};

export const getGroup = async (uuid: string, accesstoken: string) => {
  const res = await axios.get<Group>(`${EndpointsEnum.GetGroup.replace(":uuid", uuid)}`, {
    headers: {
      Authorization: `Bearer ${accesstoken}`
    }
  });
  return res.data;
};

export const deleteGroup = async (uuid: string, accesstoken: string) => {
  await axios.delete<void>(`${EndpointsEnum.DeleteGroup.replace(":uuid", uuid)}`, {
    headers: {
      Authorization: `Bearer ${accesstoken}`
    }
  });
};

export const generateIcebreakers = async (linkedinUrl: string, accesstoken: string) => {
  linkedinUrl = linkedinUrl.endsWith("/") ? linkedinUrl : linkedinUrl + "/";
  const res = await axios.get<string[]>(`${EndpointsEnum.GenerateIcebreakers.replace(":linkedinUrl", encodeURIComponent(linkedinUrl))}`, {
    headers: {
      Authorization: `Bearer ${accesstoken}`
    }
  });
  return res.data;
};

export const saveIcebreaker = async (contactUuid: string, icebreakerText: string, accesstoken: string) => {
  const res = await axios.post<IceBreaker>(
    `${EndpointsEnum.SaveIcebreaker.replace(":contact_uuid", encodeURIComponent(contactUuid))}`,
    {
      text: icebreakerText
    },
    {
      headers: {
        Authorization: `Bearer ${accesstoken}`
      }
    }
  );
  return res.data;
};

export const getCurrentUser = async (accesstoken: string) => {
  const res = await axios.get<User>(`${EndpointsEnum.GetCurrentUser}`, {
    headers: {
      Authorization: `Bearer ${accesstoken}`
    }
  });
  return res.data;
};

export const getCurrentUserForNewsFlow = async (accesstoken: string) => {
  const res = await axios.get<User>(`${EndpointsEnum.GetCurrentUserForNewsflow}`, {
    headers: {
      Authorization: `Bearer ${accesstoken}`
    }
  });
  return res.data;
};

export const resetPassword = async (email: string, accesstoken: string) => {
  const res = await axios.get<string>(`${EndpointsEnum.ResetPassword.replace(":email", email)}`, {
    headers: { Authorization: `Bearer ${accesstoken}` }
  });
  return res.data;
};

export const getStripePlans = async (accesstoken: string) => {
  const res = await axios.get<StripePlan[]>(`${EndpointsEnum.GetStripePlans}`, {
    headers: {
      Authorization: `Bearer ${accesstoken}`
    }
  });
  return res.data;
};

export const getCurrentPlan = async (accesstoken: string) => {
  const res = await axios.get<StripePlan>(`${EndpointsEnum.GetCurrentStripePlan}`, {
    headers: {
      Authorization: `Bearer ${accesstoken}`
    }
  });
  return res.data;
};

export const getIndividualStripePlans = async (planId: string, accesstoken: string) => {
  const res = await axios.get<string[]>(`${EndpointsEnum.GetIndividualStripePlan.replace(":plan_id", planId)}`, {
    headers: {
      Authorization: `Bearer ${accesstoken}`
    }
  });
  return res.data;
};

export const getStripeCheckoutRedirectLink = async (planId: string, accesstoken: string) => {
  const res = await axios.post(
    `${EndpointsEnum.GetStripeCheckout}`,
    { price_id: planId },
    {
      headers: {
        Authorization: `Bearer ${accesstoken}`
      }
    }
  );
  return res.data.checkout_link;
};

export const getStripeBillingPortal = async (accesstoken: string) => {
  const res = await axios.post(`${EndpointsEnum.GetStripeCheckout}`, null, {
    headers: {
      Authorization: `Bearer ${accesstoken}`
    }
  });
  return res.data;
};

export const upgradeSubscription = async (planId: string, accesstoken: string) => {
  await axios.post(
    `${EndpointsEnum.UpgradeSubscription}`,
    { price_id: planId },
    {
      headers: {
        Authorization: `Bearer ${accesstoken}`
      }
    }
  );
};

export const getArticles = async (pagination: number, accesstoken: string) => {
  return await axios
    .post<Article[]>(
      `${EndpointsEnum.NewsFlow}`,
      { pagination: pagination },
      {
        headers: {
          Authorization: `Bearer ${accesstoken}`
        }
      }
    )
    .then((res) => res.data);
};

export const getCompanyInfo = async (companyDomain: string, accesstoken: string) => {
  return await axios
    .get<Company>(`${EndpointsEnum.CompanyInfo.replace(":domain", encodeURIComponent(companyDomain))}`, {
      headers: { Authorization: `Bearer ${accesstoken}` }
    })
    .then((res) => res.data);
};

export const getProspects = async (body: any, accesstoken: string) => {
  return await axios
    .post<Prospect[]>(`${EndpointsEnum.ProspectSearch}`, body, {
      headers: { Authorization: `Bearer ${accesstoken}` }
    })
    .then((res) => res.data);
};

export const getNewsFlowOptions = async (accesstoken: string) => {
  return await axios.get(`${EndpointsEnum.NewsFlowOptions}`, { headers: { Authorization: `Bearer ${accesstoken}` } }).then((res) => res.data);
};

export const getProspectOptions = async (accesstoken: string) => {
  return await axios.get(`${EndpointsEnum.ProspectOptions}`, { headers: { Authorization: `Bearer ${accesstoken}` } }).then((res) => res.data);
};

export const updateProfilePreferences = async (accestoken: string, profilePrefs: string[], countryPrefs: string[]) => {
  return await axios.post(
    `${EndpointsEnum.UpdatePreferences}`,
    { newsProfile: profilePrefs, country: countryPrefs },
    { headers: { Authorization: `Bearer ${accestoken}` } }
  );
};

export const saveArticleForUser = async (articleUuid: string, accesstoken: string) => {
  return await axios
    .post(`${EndpointsEnum.SaveArticle}`, { uuid: articleUuid }, { headers: { Authorization: `Bearer ${accesstoken}` } })
    .then((res) => res.data);
};

export const isSubscribedToArticle = async (articleUuid: string, accesstoken: string) => {
  return await axios
    .get(`${EndpointsEnum.IsSubscribedToArticle.replace(":uuid", articleUuid)}`, { headers: { Authorization: `Bearer ${accesstoken}` } })
    .then((res) => res.data);
};

export const saveCompanyForUser = async (companyUuid: string, accesstoken: string) => {
  return await axios
    .post(`${EndpointsEnum.SaveCompany}`, { uuid: companyUuid }, { headers: { Authorization: `Bearer ${accesstoken}` } })
    .then((res) => res.data);
};

export const isSubscribedToCompany = async (companyUuid: string, accesstoken: string) => {
  return await axios
    .get(`${EndpointsEnum.IsSubscribedToCompany.replace(":uuid", companyUuid)}`, { headers: { Authorization: `Bearer ${accesstoken}` } })
    .then((res) => res.data);
};

export const getCompanyOfInterest = async (page: number, accesstoken: string) => {
  return await axios
    .get<CompanyOfInterest[]>(`${EndpointsEnum.GetCompanyOfInterest.replace(":page", "" + page)}`, {
      headers: { Authorization: `Bearer ${accesstoken}` }
    })
    .then((res) => res.data);
};

export const getJobListings = async (page: number, accesstoken: string) => {
  return await axios
    .get<JobListing[]>(`${EndpointsEnum.GetJobListings.replace(":page", "" + page)}`, { headers: { Authorization: `Bearer ${accesstoken}` } })
    .then((res) => res.data);
};

export const saveCompanyForOrganization = async (companyUuid: string, accesstoken: string) => {
  return await axios
    .post(`${EndpointsEnum.SaveCompanyForOrg}`, { uuid: companyUuid }, { headers: { Authorization: `Bearer ${accesstoken}` } })
    .then((res) => res.data);
};

export const isCompanySavedForOrganization = async (companyUuid: string, accesstoken: string) => {
  return await axios
    .get<boolean>(EndpointsEnum.IsCompanySavedByOrg.replace(":uuid", companyUuid), { headers: { Authorization: `Bearer ${accesstoken}` } })
    .then((res) => res.data);
};

export const fetchSavedCompaniesForOrg = async (accesstoken: string) => {
  return await axios
    .get<Company[]>(`${EndpointsEnum.GetSavedCompaniesForOrg}`, { headers: { Authorization: `Bearer ${accesstoken}` } })
    .then((res) => res.data);
};

export const sendOrganizationInvite = async (emails: string[], accesstoken: string) => {
  return await axios
    .post(`${EndpointsEnum.InviteUsers}`, { emails: emails }, { headers: { Authorization: `Bearer ${accesstoken}` } })
    .then((res) => res.data);
};

export const getOnboardingOptionsForOrg = async (accesstoken: string) => {
  return await axios
    .get<any>(`${EndpointsEnum.GetOnboardingOptions}`, { headers: { Authorization: `Bearer ${accesstoken}` } })
    .then((res) => res.data);
};

export const updateScoopPreferences = async (
  accesstoken: string,
  self_industry: string,
  company_url: string,
  target_location: string,
  target_industry: string[],
  target_roles: string,
  target_size: string[],
  news_target_industry: string,
  joblisting_location?: string,
  joblisting_industry?: string[],
  joblisting_roles?: string[],
  joblisting_departments?: string[]
) => {
  return await axios
    .post(
      `${EndpointsEnum.UpdateScoopPreferences}`,
      {
        self_industry: self_industry,
        company_url: company_url,
        target_location: target_location,
        target_industry: target_industry,
        target_size: target_size,
        target_roles: target_roles,
        article_target_industry: news_target_industry,
        joblisting_location: joblisting_location,
        joblisting_industry: joblisting_industry,
        joblisting_custom_roles: joblisting_roles,
        joblisting_departments: joblisting_departments
      },
      { headers: { Authorization: `Bearer ${accesstoken}` } }
    )
    .then((res) => res.data);
};

export const fetchCommentsForCompany = async (companyUuid: string, accesstoken: string) => {
  return await axios
    .get(`${EndpointsEnum.CommentCompanyGet.replace(":uuid", companyUuid)}`, { headers: { Authorization: `Bearer ${accesstoken}` } })
    .then((res) => res.data);
};

export const postCommentForCompany = async (companyUuid: string, message: string, accesstoken: string) => {
  return await axios
    .post<Comment>(
      `${EndpointsEnum.CommentCompanyPost}`,
      { target_uuid: companyUuid, message: message },
      { headers: { Authorization: `Bearer ${accesstoken}` } }
    )
    .then((res) => res.data as Comment);
};

export const postCommentForContact = async (contactUuid: string, message: string, accesstoken: string) => {
  return await axios
    .post<Comment>(
      `${EndpointsEnum.CommentContactPost}`,
      { target_uuid: contactUuid, message: message },
      { headers: { Authorization: `Bearer ${accesstoken}` } }
    )
    .then((res) => res.data as Comment);
};

export const editComment = async (commentUuid: string, message: string, accesstoken: string) => {
  return await axios
    .post(`${EndpointsEnum.CommentEdit}`, { comment_uuid: commentUuid, message: message }, { headers: { Authorization: `Bearer ${accesstoken}` } })
    .then((res) => res.data);
};

export const deleteComment = async (commentUuid: string, accesstoken: string) => {
  return await axios
    .delete(`${EndpointsEnum.CommentDelete.replace(":uuid", commentUuid)}`, { headers: { Authorization: `Bearer ${accesstoken}` } })
    .then((res) => res.data);
};

export const getArticlesForCompany = async (companyDomain: string, accesstoken: string) => {
  return await axios
    .get(`${EndpointsEnum.GetArticlesForCompany.replace(":domain", companyDomain)}`, { headers: { Authorization: `Bearer ${accesstoken}` } })
    .then((res) => res.data);
};

export const generateEmail = async (contactUuid: string, body: any, accesstoken: string) => {
  return await axios
    .post(`${EndpointsEnum.GenerateEmail.replace(":uuid", contactUuid)}`, body, { headers: { Authorization: `Bearer ${accesstoken}` } })
    .then((res) => res.data);
};

export const saveEmailDraft = async (contactUuid: string, draft: string, accesstoken: string) => {
  return await axios
    .post(`${EndpointsEnum.SaveEmailDraft.replace(":uuid", contactUuid)}`, { draft }, { headers: { Authorization: `Bearer ${accesstoken}` } })
    .then((res) => res.data);
};

export const saveContactFromMention = async (mention: Mention, accesstoken: string) => {
  return await axios
    .post<Contact>(`${EndpointsEnum.SaveContactFromMention}`, mention, { headers: { Authorization: `Bearer ${accesstoken}` } })
    .then((res) => res.data);
};

export const getOrganizationMembers = async (accesstoken: string) => {
  return await axios
    .get<User[]>(`${EndpointsEnum.GetOrganizationMembers}`, { headers: { Authorization: `Bearer ${accesstoken}` } })
    .then((res) => res.data);
};

export const getEmailForSavedContact = async (contact_uuid: string, accesstoken: string) => {
  return await axios
    .post<string>(
      `${EndpointsEnum.GetEmailAndEnrichExistingContact}`,
      { uuid: contact_uuid },
      { headers: { Authorization: `Bearer ${accesstoken}` } }
    )
    .then((res) => res.data);
};

export const getEmailFortUnsavedContact = async (mention: Mention, accesstoken: string) => {
  return await axios
    .post<string>(`${EndpointsEnum.GetEmailAndEnrichUnsavedContact}`, mention, { headers: { Authorization: `Bearer ${accesstoken}` } })
    .then((res) => res.data);
};

export const getOrganizationSettings = async (accesstoken: string) => {
  const res = await axios.get<User>(`${EndpointsEnum.GetOrganizationSettings}`, {
    headers: {
      Authorization: `Bearer ${accesstoken}`
    }
  });
  return res.data;
};

export const setJoblistingOwner: SetNewOwnerApiCall = async (joblistingUuid: string, ownerUuid: string, accesstoken: string) => {
  return await axios
    .post(
      `${EndpointsEnum.SetNewJoblistingOwner}`,
      { owner_uuid: ownerUuid, target_uuid: joblistingUuid },
      { headers: { Authorization: `Bearer ${accesstoken}` } }
    )
    .then((res) => res.data);
};

export const setCompanySuggestionOwner: SetNewOwnerApiCall = async (companySuggestionUuid: string, ownerUuid: string, accesstoken: string) => {
  return await axios
    .post(
      `${EndpointsEnum.SetNewCompanySuggestionOwner}`,
      { owner_uuid: ownerUuid, target_uuid: companySuggestionUuid },
      { headers: { Authorization: `Bearer ${accesstoken}` } }
    )
    .then((res) => res.data);
};

export const setArticleOwner: SetNewOwnerApiCall = async (articleUuid: string, ownerUuid: string, accesstoken: string) => {
  return await axios
    .post(
      `${EndpointsEnum.SetNewArticleOwner}`,
      { owner_uuid: ownerUuid, target_uuid: articleUuid },
      { headers: { Authorization: `Bearer ${accesstoken}` } }
    )
    .then((res) => res.data);
};

export const setCompanyOwner: SetNewOwnerApiCall = async (companyUuid: string, ownerUuid: string, accesstoken: string) => {
  return await axios
    .post(
      `${EndpointsEnum.SetNewCompanyOwner}`,
      { owner_uuid: ownerUuid, target_uuid: companyUuid },
      { headers: { Authorization: `Bearer ${accesstoken}` } }
    )
    .then((res) => res.data);
};

export const getCompanyRelatedJobListings = async (companyUuid: string, accessToken: string) => {
  const response = await axios.get<JobListing[]>(`${EndpointsEnum.GetCompanyRelatedJobListings.replace(":uuid", companyUuid)}`, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
  return response.data;
};

export const getStatusTags = async (accessToken: string) => {
  const res = await axios.get<Status[]>(`${EndpointsEnum.GetStatusTags}`, { headers: { Authorization: `Bearer ${accessToken}` } });
  return res.data;
};

export const setContactStatus = async (contactUuid: string, statusName: string, accessToken: string) => {
  const res = await axios.post(
    `${EndpointsEnum.SetContactStatus}`,
    { target_uuid: contactUuid, tag_name: statusName },
    { headers: { Authorization: `Bearer ${accessToken}` } }
  );
  return res.data;
};

export const getSavedContactsForCompany = async (companyDomain: string, accessToken: string) => {
  const res = await axios.get<Contact[]>(
    `${EndpointsEnum.GetSavedContactsForCompany.replace(":company_domain", encodeURIComponent(companyDomain))}`,
    {
      headers: { Authorization: `Bearer ${accessToken}` }
    }
  );
  return res.data;
};

export const setCompanyTag = async (companyUuid: string, tagName: string, accesstoken: string) => {
  return await axios
    .post(`${EndpointsEnum.SetCompanyTag}`, { tag_name: tagName, target_uuid: companyUuid }, { headers: { Authorization: `Bearer ${accesstoken}` } })
    .then((res) => res.data);
};

export const unsetCompanyTag = async (companyUuid: string, tagName: string, accesstoken: string) => {
  return await axios
    .post(
      `${EndpointsEnum.UnsetCompanyTag}`,
      { tag_name: tagName, target_uuid: companyUuid },
      { headers: { Authorization: `Bearer ${accesstoken}` } }
    )
    .then((res) => res.data);
};

export const deleteCompanyTag = async (tagName: string, accesstoken: string) => {
  return await axios
    .delete(`${EndpointsEnum.DeleteCompanyTag}`, {
      data: { tag_name: tagName },
      headers: { Authorization: `Bearer ${accesstoken}` }
    })
    .then((res) => res.data);
};

export const getCompanyTags = async (accesstoken: string) => {
  return await axios.get(`${EndpointsEnum.GetCompanyTags}`, { headers: { Authorization: `Bearer ${accesstoken}` } }).then((res) => res.data);
};

export const sendCancelNotification = async (uuid: string, accesstoken: string) => {
  return await axios
    .post(`${EndpointsEnum.CancelReminder}`, { uuid: uuid }, { headers: { Authorization: `Bearer ${accesstoken}` } })
    .then((res) => res.data);
};

export const sendCreateNotification = async (uuid: string, unixDateTime: number, accesstoken: string) => {
  return await axios
    .post(`${EndpointsEnum.ScheduleReminder}`, { uuid: uuid, send_at_unix: unixDateTime }, { headers: { Authorization: `Bearer ${accesstoken}` } })
    .then((res) => res.data);
};

export const getArticleEventTags = async (accesstoken: string) => {
  return await axios.get(`${EndpointsEnum.GetArticleEventTags}`, { headers: { Authorization: `Bearer ${accesstoken}` } }).then((res) => res.data);
};

export const getWebsiteVisitScript = async (accesstoken: string) => {
  return await axios.get(`${EndpointsEnum.GetWebsiteVisitScript}`, { headers: { Authorization: `Bearer ${accesstoken}` } }).then((res) => res.data);
};

export const getWebsiteVisits = async (accesstoken: string) => {
  return await axios.get(`${EndpointsEnum.GetWebsiteVisits}`, { headers: { Authorization: `Bearer ${accesstoken}` } }).then((res) => res.data);
};

export const getWebsiteVisitsPaginated = async (pagination: number, accesstoken: string) => {
  return await axios
    .get(`${EndpointsEnum.GetWebsiteVisitsPaginated}?page=${pagination}`, { headers: { Authorization: `Bearer ${accesstoken}` } })
    .then((res) => res.data);
};

export const getJobListingsPaginated = async (pagination: number, accesstoken: string) => {
  return await axios
    .get(`${EndpointsEnum.GetJobListingsPaginated}?page=${pagination}`, { headers: { Authorization: `Bearer ${accesstoken}` } })
    .then((res) => res.data);
};

export const addContactWithInfo = async (contactData: any, accesstoken: string) => {
  return await axios
    .post(`${EndpointsEnum.AddContactWithInfo}`, contactData, { headers: { Authorization: `Bearer ${accesstoken}` } })
    .then((res) => res.data);
};

export const saveContactsFromFile = async (file: File, accesstoken: string) => {
  const formData = new FormData();
  formData.append("file", file);
  return await axios
    .post(`${EndpointsEnum.SaveContactsFromFile}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accesstoken}`
      }
    })
    .then((res) => res.data);
};
